import { experimental_extendTheme as materialExtendTheme } from "@mui/material/styles";

export const materialTheme = materialExtendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: "#E6F6FF",
          100: "#BFE8FF",
          200: "#99D9FF",
          300: "#73CBFF",
          400: "#4CCDFF",
          500: "#00A0C3",
          600: "#008DA6",
          700: "#00798A",
          800: "#00656D",
          900: "#005250",
        },
      },
    },
  },
});
