import { AxiosResponse } from "axios";
import axios from "../lib/axios";
import { Company } from "@/gen/openapi";
export const settingApiPath = "/api/company";

/** @deprecated OpenAPIから生成したクライアントを使用してください。 */
export const storeSetting = async (payload: any): Promise<AxiosResponse<any, any>> => {
  const axiosRef = axios.post(`${settingApiPath}/create`, payload);
  return axiosRef
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
};

/** @deprecated OpenAPIから生成したクライアントを使用してください。 */
export const getSetting = async (): Promise<AxiosResponse<Company>> => {
  return axios
    .get(`${settingApiPath}`)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
};
