import { extendTheme } from "@mui/joy/styles";

export const joyTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: "#E6F6FF",
          100: "#BFE8FF",
          200: "#99D9FF",
          300: "#73CBFF",
          400: "#4CCDFF",
          500: "#00A0C3",
          600: "#008DA6",
          700: "#00798A",
          800: "#00656D",
          900: "#005250",
        },
        background: {
          backdrop: "#6b7280bf",
        },
        danger: {
          solidHoverBg: "#ef4444",
          solidBg: "#dc2626",
        },
      },
    },
  },
  fontWeight: {
    lg: 500,
  },
  components: {
    JoyModal: {
      styleOverrides: {
        backdrop: {
          backdropFilter: "unset",
        },
      },
    },
  },
});
