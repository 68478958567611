import { IInitialStateContext, StateReducer } from "../interfaces/index";

export const initialState: IInitialStateContext = {
  furnituresClone: [],
};

const reducer: StateReducer = (state, action) => {
  switch (action.type) {
    case "GET_FURNITURE_FOR_AREAS":
      return {
        ...state,
        furnituresClone: action?.furnituresClone,
      };
  }
};

export default reducer;
