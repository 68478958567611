/**
  既存のenv名を以下で定義されているSentryのenv名に変換する
  https://swish-0w.sentry.io/settings/projects/swish-frontend/environments/

  TODO: SW-625 変換が不要なように元のenv名を修正する

  @param {'local'|'dev'|'prod'} env - 既存のenv名
 */
const getSentryEnvName = (env) => {
  if (env === "dev") return "development";
  if (env === "prod") return "production";
  return env;
};

module.exports = getSentryEnvName;
