import { Box, Button, DialogContent, DialogTitle, Modal, ModalDialog } from "@mui/joy";
import React, { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { WarningAmberRounded } from "@mui/icons-material";

export interface ConfirmModalRef {
  confirm: (props?: ConfirmModalProps) => Promise<boolean>;
}

export interface ConfirmModalProps {
  /** @default "確認" */
  title?: React.ReactNode;
  icon?: React.ReactNode;
  /** @default "実行しますか？" */
  body?: React.ReactNode;
  /** @default "キャンセル" */
  cancelText?: React.ReactNode;
  /** @default "OK" */
  submitText?: React.ReactNode;
  /** @default false */
  disableCancel?: boolean;
  disableBackdropClick?: boolean;
}

type Resolver = (_: boolean) => void;

export const ConfirmModal = forwardRef<ConfirmModalRef>((_, ref: React.Ref<ConfirmModalRef>) => {
  const [props, setProps] = useState<ConfirmModalProps>({});

  const {
    title = "確認",
    icon,
    body = "実行しますか？",
    cancelText = "キャンセル",
    submitText = "OK",
    disableCancel = false,
    disableBackdropClick = false,
  } = props;

  const [resolveConfirmation, setResolveConfirmation] = useState<Resolver>();
  useImperativeHandle(ref, () => ({
    confirm: (props?: ConfirmModalProps) => {
      setProps(props ?? {});
      return new Promise((resolve) => {
        setResolveConfirmation(() => {
          return resolve;
        });
      });
    },
  }));

  const handleCancel = useMemo(() => {
    if (resolveConfirmation == null) return undefined;

    return () => {
      resolveConfirmation(false);
      setResolveConfirmation(undefined);
    };
  }, [resolveConfirmation]);

  const handleSubmit = useMemo(() => {
    if (resolveConfirmation == null) return undefined;
    return () => {
      resolveConfirmation(true);
      setResolveConfirmation(undefined);
    };
  }, [resolveConfirmation]);

  return (
    <Modal open={Boolean(resolveConfirmation)} onClose={disableBackdropClick ? undefined : handleCancel}>
      <ModalDialog minWidth="512px" className="!p-0">
        <div className="flex p-6 pb-1 gap-4">
          {icon ?? (
            <div className="bg-red-100 h-10 w-10 rounded-full grid place-items-center text-red-600">
              <WarningAmberRounded />
            </div>
          )}
          <div className="flex flex-col gap-2 grow">
            {title != null && <DialogTitle>{title}</DialogTitle>}
            <DialogContent className="whitespace-pre-wrap text-sm">{body}</DialogContent>
          </div>
        </div>

        <Box className="flex gap-3 justify-end bg-gray-50 px-6 py-3 rounded-b-full">
          {!disableCancel && (
            <Button variant="outlined" color="neutral" onClick={handleCancel}>
              {cancelText}
            </Button>
          )}
          <Button variant="solid" color="danger" onClick={handleSubmit}>
            {submitText}
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
});

ConfirmModal.displayName = "ConfirmModal";
