import { DefaultApi } from "@/gen/openapi";
import Axios, { AxiosInstance } from "axios";
import { CookieValueTypes, getCookie } from "cookies-next";

const axios: AxiosInstance = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_BACKEND_URL,
  headers: {
    Accept: "application/json",
  },
  withCredentials: true,
});

axios.interceptors.request.use(
  (config) => {
    const token: CookieValueTypes = getCookie("_token");
    if (token && config) {
      config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axios;

export const api = new DefaultApi(undefined, undefined, axios);
