import "../styles/globals.css";
import "react-datasheet-grid/dist/style.css";
import "@silevis/reactgrid/styles.css";
import { StateProvider } from "../utils/StateProvider";
import reducer, { initialState } from "../utils/reducer";
import ProgressBar from "../components/Common/ProgressBar";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useAuth } from "../hooks/auth";
import Head from "next/head";
import "@fontsource/inter";
import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import { materialTheme } from "@/theme/mui";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import { joyTheme } from "@/theme/joy";

import "dayjs/locale/ja";
import { useMount, useUpdateEffect } from "react-use";
import TagManager from "react-gtm-module";
import { useFullstar } from "@/hooks/useFullstar";
import { ConfirmModalProvider } from "@/components/Common/ConfirmModal";
import { setupLocalization } from "@/lib/localization";

setupLocalization();

function MyApp({ Component, pageProps }: any) {
  const router = useRouter();
  const [state, setState] = useState({
    isRouteChanging: false,
    loadingKey: 0,
  });
  const { user } = useAuth();
  useEffect(() => {
    if (!user && ["/home", "/setting"].includes(router.pathname)) {
      router.push("/login");
    }

    const handleRouteChangeStart = () => {
      setState((prevState) => ({
        ...prevState,
        isRouteChanging: true,
        loadingKey: prevState.loadingKey ^ 1,
      }));
    };

    const handleRouteChangeEnd = () => {
      setState((prevState) => ({
        ...prevState,
        isRouteChanging: false,
      }));
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeEnd);
    router.events.on("routeChangeError", handleRouteChangeEnd);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeEnd);
      router.events.off("routeChangeError", handleRouteChangeEnd);
    };
  }, [user, router]);

  useMount(() => {
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID });
  });

  useUpdateEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "set_user_id",
        user_id: user?.data.id ?? null,
      },
    });
  }, [user?.data.id]);

  useFullstar();

  return (
    <>
      <Head>
        <link rel="icon" href="/head_icon.png" />
        <title>Swish</title>
      </Head>
      <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
        <JoyCssVarsProvider theme={joyTheme}>
          <StateProvider initialState={initialState} reducer={reducer}>
            <ProgressBar isRouteChanging={state.isRouteChanging} key={state.loadingKey} />
            <ConfirmModalProvider>
              <Component {...pageProps} />
            </ConfirmModalProvider>
          </StateProvider>
        </JoyCssVarsProvider>
      </MaterialCssVarsProvider>
    </>
  );
}

export default MyApp;
