import React, { createContext, Dispatch, ReducerAction, useContext, useReducer } from "react";
import { IInitialStateContext, StateReducer } from "../interfaces/index";

interface StateProvierProps {
  reducer: StateReducer;
  initialState: IInitialStateContext;
  children: React.ReactNode;
}

type IStateContext = [IInitialStateContext, Dispatch<ReducerAction<StateReducer>>];

export const StateContext = createContext({} as IStateContext);

export const StateProvider = ({ reducer, initialState, children }: StateProvierProps) => {
  return <StateContext.Provider value={useReducer(reducer, initialState)}>{children}</StateContext.Provider>;
};
export const useStateValue = () => useContext(StateContext);
