import useSWR from "swr";
import { getSetting, settingApiPath } from "@/services/setting";

export const useCompany = (enabled = true) => {
  const { data: company, mutate: mutateCompany } = useSWR(
    settingApiPath,
    enabled ? () => getSetting().then((res) => res.data) : null
  );

  return { company, mutateCompany };
};
