import React, { createContext, useCallback, useRef } from "react";
import { ConfirmModal, ConfirmModalProps, ConfirmModalRef } from "@/components/Common/ConfirmModal/ConfirmModal";

const ConfirmModalContext = createContext<ConfirmModalRef | null>(null);

export const ConfirmModalProvider = (props: { children: React.ReactNode }) => {
  const ref = useRef<ConfirmModalRef | null>(null);
  const confirm = useCallback(async (props?: ConfirmModalProps) => ref.current?.confirm(props) ?? false, []);

  return (
    <ConfirmModalContext.Provider value={{ confirm }}>
      {props.children}
      <ConfirmModal ref={ref} />
    </ConfirmModalContext.Provider>
  );
};

export const useConfirmModal = () => {
  const context = React.useContext(ConfirmModalContext);
  if (context == null) throw new Error("useConfirmModal() must be used within a ConfirmModalProvider");
  return context;
};
