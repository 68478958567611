// @ts-nocheck
import useSWR from "swr";
import axios from "../lib/axios";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { setCookie, deleteCookie } from "cookies-next";
import { signOut } from "../components/Login/FirebaseAuth";

const makeAuthHook = (authApiPath: string) => {
  return ({ middleware, redirectIfAuthenticated } = {}) => {
    const router = useRouter();

    const {
      data: user,
      error,
      mutate,
    } = useSWR("/api/user", () =>
      axios
        .get("/api/auth/user")
        .then((res) => {
          if (res.data.status) {
            return res.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          throw error;
        })
    );

    const login = async ({ ...props }) => {
      // await csrf()
      return axios.post(authApiPath, props).then((res) => {
        if (res.data.status) {
          setCookie("_token", res.data.data.token);
          localStorage.setItem("_token", res.data.data.token);
        }
        mutate();
      });
    };

    const logout = async () => {
      if (!error) {
        localStorage.clear();
        sessionStorage.clear();
        deleteCookie("_token");
        signOut();
        mutate();
      }

      window.location.pathname = "/login";
    };

    useEffect(() => {
      if (middleware === "guest" && redirectIfAuthenticated && user) router.push(redirectIfAuthenticated);
      if (middleware === "auth" && error) logout();
    }, [user, error]);

    return {
      user,
      login,
      logout,
    };
  };
};

export const usePasswordAuth = makeAuthHook("/api/auth/login");
export const useFirebaseAuth = makeAuthHook("/api/auth/firebase");
export const useAuth = useFirebaseAuth; // 互換性の維持
