import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useFirebaseAuth } from "../../hooks/auth";
import axios from "axios";
import toast from "react-hot-toast";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const HTTP_FORBIDDEN = 403;

const StyledFirebaseAuth = dynamic(() => import("@/components/Login/StyledFirebaseAuth"), {
  ssr: false,
});

const firebaseAuthConfig = {
  signInFlow: "popup",
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    },
    {
      provider: "microsoft.com",
    },
  ],
  credentialHelper: "none",
  callbacks: {
    signInSuccessWithAuthResult: () => false,
  },
};

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_APP_ID,
};

firebase.initializeApp(firebaseConfig);

const FirebaseAuth = () => {
  const [renderAuth, setRenderAuth] = useState(false);

  const { login } = useFirebaseAuth({
    middleware: "guest",
    redirectIfAuthenticated: "/home",
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      setRenderAuth(true);
    }
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async function (user) {
      if (!user) return;
      const token = await user.getIdToken(true);

      const data = {
        firebase_id_token: token,
      };
      try {
        await login({ ...data });
      } catch (ex) {
        if (axios.isAxiosError(ex) && ex.response?.status == HTTP_FORBIDDEN) {
          toast.custom((t) => <UnregisteredUser onRemove={() => toast.remove(t.id)} />, {
            id: "unregistered-user",
            duration: Infinity,
          });
        }
      }
    });
    return () => unregisterAuthObserver();
  }, []);
  return <div>{renderAuth && <StyledFirebaseAuth uiConfig={firebaseAuthConfig} firebaseAuth={firebase.auth()} />}</div>;
};

function UnregisteredUser(props: { onRemove: () => void }) {
  return (
    <div role="alert" className="rounded border-s-4 border-red-500 bg-red-50 p-4 relative">
      <IconButton className="!absolute right-0 top-0" size="small" onClick={props.onRemove}>
        <Close />
      </IconButton>
      <div className="flex items-center gap-2 text-red-800">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5">
          <path
            fillRule="evenodd"
            d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
            clipRule="evenodd"
          />
        </svg>

        <strong className="block font-medium">メールアドレスが未登録です</strong>
      </div>

      <p className="text-sm text-red-700 text-left">
        外部サービスのアカウントを利用している場合は、登録済みのメールアドレスでログインしてください。
        <br />
        問題が解決しない場合は運営者までお問い合わせください。
      </p>
    </div>
  );
}

const signOut = () => {
  firebase.auth().signOut();
};

export { FirebaseAuth, signOut };
