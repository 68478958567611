import { useAuth } from "@/hooks/auth";
import { useCompany } from "@/hooks/useCompany";
import { useUpdateEffect } from "react-use";
import { useEffect } from "react";

export const useFullstar = () => {
  const { user } = useAuth();
  const { company, mutateCompany } = useCompany(user != null);

  // ユーザー情報が取得できたら会社情報を取得
  useEffect(() => {
    if (user?.data.id == null) return;
    mutateCompany();
  }, [mutateCompany, user?.data.id]);

  useUpdateEffect(() => {
    if (!user || !company) return;
    fs.clear();
    fs.show(process.env.NEXT_PUBLIC_FULLSTAR_ID, user.data.id, {
      company_name: company.company_name,
      user_name: user.data.display_name,
      user_email: user.data.email,
      plan_name: "",
      tags: "",
    });
    fs.tracking();
    fs.survey();
    fs.guide();
    fs.checklist();
    fs.tooltip();
  }, [company?.id, user?.data.id]);
};
